<script>
export default {
  name: 'BaselineCoverage',
  props: {
    tableValues: {
      type: Object,
      required: true,
    },
    aptitudeAndWillingness: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      switch1: false,
      highlightedCol: null,
      highlightedRow: null,
    };
  },
  computed: {
    awSlider() {
      return 25 - this.aptitudeAndWillingness;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<template>
  <div>
    <table>
      <thead>
        <tr>
          <th v-if="switch1" />
          <th
            v-for="(zone, z) in tableValues.columnHeadings"
            :key="zone"
            @mouseover="highlightedCol = z"
            @mouseleave="highlightedCol = null"
          >
            <span>{{ zone }}</span>
          </th>
          <th v-if="!switch1" />
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, i) in tableValues.rowHeadings" :key="row">
          <th
            v-if="switch1"
            :class="{
              empty: tableValues.cells[i].filter(c => c.selected).length === 0,
            }"
            @mouseover="highlightedRow = i"
            @mouseleave="highlightedRow = null"
          >
            {{ row }}
          </th>
          <template v-for="(z, j) in tableValues.columnHeadings">
            <v-tooltip
              :key="z"
              :disabled="!tableValues.cells[i][j].instances"
              top
            >
              <template #activator="{ on, attrs }">
                <td
                  slot="activator"
                  v-bind="attrs"
                  :class="{
                    selected: tableValues.cells[i][j].selected,
                    'highlight-row': highlightedRow === i,
                    'highlight-column': highlightedCol === j,
                    'highlight-row-preceding': highlightedRow === i + 1,
                    'highlight-column-preceding': highlightedCol === j + 1,
                  }"
                  v-on="on"
                />
              </template>
              <span>{{
                `${tableValues.cells[i][j].instances} Instances`
              }}</span>
            </v-tooltip>
          </template>
          <template v-if="!switch1">
            <th
              slot="activator"
              :class="{
                empty:
                  tableValues.cells[i].filter(c => c.selected).length === 0,
              }"
              @mouseover="highlightedRow = i"
              @mouseleave="highlightedRow = null"
            >
              {{ row }}
            </th>
          </template>
        </tr>
      </tbody>
      <caption>
        {{
          `Date of last analysis: ${
            new Date(new Date().getTime() - 1000000100)
              .toISOString()
              .split('T')[0]
          }`
        }}
      </caption>
    </table>
    <v-switch
      v-model="switch1"
      :label="`Orientation: ${{ true: 'Left', false: 'Right' }[switch1]}`"
      class="switch"
    />
    <v-btn icon @click="$emit('reSeed')"> <v-icon>mdi-cached</v-icon></v-btn>
    <div
      style="
        display: inline-block;
        width: 400px;
        padding: 0 0 0 40px;
        margin: 0;
        position: relative;
        top: 16px;
      "
    >
      <v-slider
        :value="awSlider"
        :min="0"
        :max="25"
        color="action"
        thumb-color="grey"
        track-color="grey"
        thumb-label="always"
        inverse-label
        label="Aptitude &amp; Willingness"
        @end="$emit('aptitudeAndWillingness', 25 - $event)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
table {
  border-collapse: collapse;
}
table,
tr,
td,
th {
  font-weight: 400;
}
th {
  position: relative;
  font-size: 0.75rem;
}
td {
  width: 2rem;
  height: 2rem;
}
thead th span {
  letter-spacing: 0.1em;
  text-transform: capitalize;
  writing-mode: vertical-rl;
  text-orientation: sideways;
  transform: rotate(-180deg);
  min-height: 100px;
  text-align: left;
}
td.selected {
  background-color: var(--v-complementary-base);
  box-shadow: inset 0 0 0 3px #fff;
}
tbody th {
  padding-right: 1rem;
  text-align: right;
  min-width: 100px;
}
tbody td ~ th {
  text-align: left;
  padding-left: 1rem;
  padding-right: 0;
}
thead th {
  vertical-align: bottom;
  padding-bottom: 0.5rem;
}
td {
  border: 1px solid var(--v-grfn-darken1);
  min-width: 2rem;
  min-height: 2rem;
}
.switch {
  margin-left: 0.75rem;
  margin-right: 2rem;
  margin-top: 30px;
  display: inline-flex;
  transform: translateY(0.25rem);
}
th.empty {
  color: var(--v-negative-base);
  font-weight: 600;
}

// highlighted columns and rows
.highlight-column,
.highlight-row {
  border-color: var(--v-grfn-darken5);
}

.highlight-column {
  border-bottom-color: var(--v-grfn-darken1);
}
.highlight-row {
  border-right-color: var(--v-grfn-darken1);
}

// Correcting for collapsed borders
.highlight-column-preceding {
  border-right-color: var(--v-grfn-darken5);
}

.highlight-row-preceding {
  border-bottom-color: var(--v-grfn-darken5);
}

tbody > tr:last-child .highlight-column {
  border-bottom-color: var(--v-grfn-darken5);
}

tbody td.highlight-row:last-of-type {
  border-right-color: var(--v-grfn-darken5);
  border-right-width: 1px;
}

caption {
  caption-side: bottom;
  text-align: left;
  font-family: monospace;
  font-size: 0.85rem;
  padding-top: 8px;
}
</style>
